(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@wix/image-kit"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define("rb_wixui.thunderbolt_bootstrap-classic", ["imageClientApi", "react"], factory);
	else if(typeof exports === 'object')
		exports["rb_wixui.thunderbolt_bootstrap-classic"] = factory(require("@wix/image-kit"), require("react"));
	else
		root["rb_wixui.thunderbolt_bootstrap-classic"] = factory(root["__imageClientApi__"], root["React"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__42662__, __WEBPACK_EXTERNAL_MODULE__5329__) {
return 